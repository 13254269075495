<script setup>

</script>

<template>
  <footer class="absolute inset-x-0 bottom-0 p-4 text-black">
    <div class="text-center sm:float-right text-black dark:text-white">

      <a
          :href="route('static.imprint')"
          class="inline-block p-2 text-xs opacity-25 cursor-pointer text-[gray] hover:text-sky-700 hover:opacity-100"
          style="text-decoration: none;"
      >{{ $t('imprint') }}</a
      >
      <a
          :href="route('static.privacyPolicy')"
          class="inline-block p-2 text-xs opacity-25 cursor-pointer text-[gray] hover:text-sky-700 hover:opacity-100"
          style="text-decoration: none;"
      >{{ $t('privacyPolicy') }}</a
      >
      <a
          class="inline-block p-2 text-xs opacity-25 cursor-pointer text-[gray] hover:text-sky-700 hover:opacity-100"
          href="mailto:abuse@nicloud.de"
          style="text-decoration: none;"
      >Abuse</a>


    </div>
    <div class="text-center sm:text-start text-black dark:text-white">
      <a
        class="inline-block p-2 text-xs opacity-25 cursor-pointer text-[gray] hover:text-sky-700 hover:opacity-100"
        href="https://netinsiders.de "
        style="text-decoration: none;"
        target="_blank"
      >
        © 2024 netinsiders | Werft für Mediendesign
      </a>
    </div>

  </footer>
</template>

<style scoped>
footer {
  bottom: 0;
  left: 0;
  padding: 1rem;
  position: absolute;
  right: 0
}

footer a, footer span {
  color: inherit;
  display: inline-block;
  font-size: .75rem;
  opacity: .25;
  padding: .5rem
}

footer a:hover {
  color: inherit;
  opacity: 1;
  text-decoration: none
}
</style>